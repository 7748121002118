import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { Image as VanImage, Button, Uploader, Loading, Toast } from 'vant';
import { customizeConfig } from '../../../api/product.js';
import { submitCart } from '../../../api/shoppingcart';
import { post } from '../../../util/request';
import { addressDefault, profile, customizeGuide } from '../../../api/user';
import Hammer from 'hammerjs';
import BackOff from '../../common/BackOff.vue';
import AddAddressPopup from '@/views/common/AddAddressPopup.vue';
// import html2canvas from 'html2canvas'

export default {
  name: 'CommonCustom',
  components: {
    BackOff,
    AddAddressPopup,
    [Toast.name]: Toast,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Loading.name]: Loading
  },
  directives: {
    // 使用局部注册指令的方式
  },
  data() {
    return {
      guideContent: ['Next Tip', 'Next Tip', 'Close Tip'],
      guideList: ['http:\/\/udefine-guigu-1305490312.cos.na-siliconvalley.myqcloud.com\/user\/20230701\/e2687acf-9141-4d67-a5a6-e881de94af16.png', 'http:\/\/udefine-guigu-1305490312.cos.na-siliconvalley.myqcloud.com\/user\/20230701\/f4f04f39-08ab-4e73-9585-4c26f7e0afdc.png', 'http:\/\/udefine-guigu-1305490312.cos.na-siliconvalley.myqcloud.com\/user\/20230701\/e64d05a4-517f-41ed-a746-f2b682386a12.png'],
      guideIndex: 0,
      showGuide: false,
      showGuideStorage: false,
      canMovePicture: false,
      x: 0,
      y: 0,
      scale: 1,
      centerX: 0,
      centerY: 0,
      isPanching: true,
      imagesLoaded: false,
      imageCount: 0,
      loadedCount: 0,
      spanWidth: String,
      spanHeight: String,
      customizeInfo: {},
      // url携带的商品参数
      // skuId: this.$route.query.skuId,
      productId: this.$route.query.product_id,
      skuId: this.$route.query.sku_id,
      backUrl: '/editor/detail?productId=' + this.$route.query.product_id,
      uploadPictureShow: false,
      imgUploadingShow: false,
      // 上传loading遮罩层显示控制
      postOrderLoadingShow: false,
      // 提交订单的loading
      addAddressFlag: false,
      // 新增地址弹窗

      cartItemId: 0,
      // 用户制作好之后，提交到购物车接口，返回的新增的购物车id
      userDefaultAddressId: 0,
      // 用户的默认地址id
      userInfo: {},
      productConfig: {},
      // 接口获取的商品定制配置

      clientScale: 0,
      // 客户端的缩放比例，客户端画布的宽 / 画布的原始宽

      // 定制区域计算的位置
      cusAreaW: '0px',
      cusAreaH: '0px',
      cusAreaX: '0px',
      cusAreaY: '0px',
      cusAreaShow: false,
      cusAreaBorder: '1px dashed #1989fa',
      cusAreaBorderShow: true,
      // 用户上传的图片计算的位置
      userImgW: '0px',
      userImgH: '0px',
      userImgX: '0px',
      userImgY: '0px',
      userImgShow: false,
      // 用户高操作时候的图片边框
      operateBorder: '0px dashed #1989fa',
      operateBorderShow: false,
      // 上次移动结束之后的宽高和位置信息
      lastMoveAttr: {
        X: 0,
        // 移动过程中X，Y会发生变化,移动结束之后清空
        Y: 0,
        w: 0,
        // 移动开始的时候记录w，h的初始值，移动结束之后清空，移动过程中保持不变
        h: 0,
        scale: 0 // 移动过程中需要记录
      },
      // 定制配置中提取的后台画布配置
      canvasConfig: {
        remindImg: '',
        bgImg: '',
        coverImg: '',
        width: 0,
        height: 0
      },
      // 定制配置中提取的后台定制区域配置
      cusAreaConfig: {
        w: 0,
        h: 0,
        x: 0,
        y: 0
      },
      // 定制区域计算值
      cusAreaData: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        calFinish: false
      },
      // 用户上传的图片的信息
      userImgData: {
        src: '',
        width: 0,
        height: 0,
        widthRate: 0,
        heightRate: 0,
        x: 0,
        y: 0,
        xRate: 0,
        yRate: 0,
        calFinish: false,
        position: {
          // 相对于定制区域左上角的坐标
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      }
    };
  },
  created: async function () {
    this.getProductConfig();
    this.getUserDefaultAddress();
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      profile().then(res => {
        this.showGuideStorage = res.data.data.user.customizeGuide;
      });
    },
    closeGuide() {
      if (this.guideIndex < 2) {
        this.guideIndex++;
      } else {
        this.showGuide = false;
        this.showGuideStorage = true;
        this.guideIndex = 0;
        customizeGuide().then(res => {
          res;
        });
      }
    },
    // checkPicture() {
    //   // 获取图片和div元素
    //   const test = document
    //     .getElementById('custom-user-image-option-box')
    //     .getBoundingClientRect()
    //   const parent = document
    //     .getElementById('custom-cusArea-box')
    //     .getBoundingClientRect()
    //   if (parent.top < test.top) {
    //     this.y = this.y + (parent.top - test.top) / this.scale
    //   }
    //   if (parent.bottom > test.bottom) {]
    //     this.y = this.y + (parent.bottom - test.bottom) / this.scale
    //   }
    //   if (parent.left < test.left) {
    //     this.x = this.x + (parent.left - test.left) / this.scale
    //   }
    //   if (parent.right > test.right) {
    //     this.x = this.x + (parent.right - test.right) / this.scale
    //   }

    //   this.initX = this.x
    //   this.initY = this.y
    //   // 更新图片的位置
    //   this.$refs.userImage.$el.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`
    //   this.$refs.image.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`

    //   this.calImgPosition()
    // },
    canNotMove() {
      this.addAddressFlag = false;
      this.canMovePicture = false;
      this.operateBorder = '0px dashed #ff0000';
      this.move();
    },
    canMove() {
      event.stopPropagation();
      this.addAddressFlag = false;
      this.canMovePicture = true;
      this.operateBorder = '1px dashed #ff0000';
      this.move();
    },
    move() {
      const mc = new Hammer.Manager(document.getElementById('custom-user-image-option-box'));
      var pinch = null;
      var pan = null;
      if (this.canMovePicture) {
        pinch = new Hammer.Pinch();
        pan = new Hammer.Pan();
        mc.add(pinch);
        mc.add(pan);
      } else {
        mc.off('pinch', pinch);
        mc.off('pan', pan);
      }

      // const initScale = 1

      let initScale = 1;
      let initX = this.x;
      let initY = this.y;
      let isPinching = false; // 新增的标志位

      // const test = document
      //   .getElementById('custom-user-image-option-box')
      //   .getBoundingClientRect()

      mc.on('pinchstart', event => {
        if (!this.canMovePicture) {
          return;
        }
        initScale = this.scale / event.scale || 1;
        isPinching = true;
      });
      mc.on('pinch', event => {
        if (!this.canMovePicture) {
          return;
        }
        this.scale = initScale * event.scale;
        // this.$refs.image.style.transformOrigin = `${this.centerX}px ${this.centerY}px`
        this.$refs.image.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`;
        // this.$refs.userImage.$el.style.transformOrigin = `${this.centerX}px ${this.centerY}px`
        this.$refs.userImage.$el.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`;
      });
      mc.on('pan', event => {
        if (!this.canMovePicture) {
          return;
        }
        if (isPinching) {
          event.deltaX = 0;
          event.deltaY = 0;
        }

        // 只有在不进行捏手势时执行平移操作
        this.x = initX + event.deltaX / this.scale;
        this.y = initY + event.deltaY / this.scale;
        this.$refs.userImage.$el.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`;
        this.$refs.image.style.transform = `scale(${this.scale}) translate(${this.x}px, ${this.y}px)`;
      });
      mc.on('panend', () => {
        if (!this.canMovePicture) {
          return;
        }
        initX = this.x;
        initY = this.y;

        // this.checkPicture()
        // this.calImgPosition()
      });
      mc.on('pinchend', () => {
        if (!this.canMovePicture) {
          return;
        }
        initX = this.x;
        initY = this.y;
        setTimeout(() => {
          isPinching = false;
        }, 250); // 设置标志位为false
        // this.checkPicture()
        // this.calImgPosition()
      });
    },
    imageLoaded() {
      this.imagesLoaded = true;
      // }
    },
    onOversize(file) {
      Toast.setDefaultOptions({
        duration: 2000
      });
      Toast({
        message: 'The picture cannot be larger than 25M'
      });
    },
    getUserDefaultAddress() {
      addressDefault().then(res => {
        if (res.data.data.address !== undefined) {
          this.userDefaultAddressId = res.data.data.address.id;
        }
      });
    },
    onClickLeft() {
      this.$router.push({
        path: '/product/detail',
        query: {
          productId: this.product_id
        }
      });
    },
    // 获取定制配置
    getProductConfig() {
      const params = {
        productId: this.productId,
        skuId: this.skuId
      };
      customizeConfig(params).then(res => {
        this.productConfig = res.data.data.customizeInfo;
        this.initCanvasConfig();
        this.initCusAreaConfig();
        this.calculateCusPosition();
        // eslint-disable-next-line eqeqeq

        const imageData = JSON.parse(localStorage.getItem('localPictureInfoStorage' + this.skuId));
        if (imageData) {
          this.imagesLoaded = true;
          const coverImgData = imageData.detail.customize[0].coverImgs[0];
          const userImgInfo = imageData.detail.customize[0].userImgInfo[0];
          // 用图片在屏幕里的大小和实际的大小相除,得到缩放比例zoomRate
          const coverData = document.getElementById('custom-canvas-image-box').getBoundingClientRect();
          const zoomRate = coverData.width / coverImgData.width;
          // 计算用户图片相对于背景图片的位置
          const a = parseInt(userImgInfo.x);
          this.userImgX = a * zoomRate + 'px';
          // this.userImageX = 200 * zoomRate + 'px';
          // this.useImageXPercentage = useImageX / this.coverImgData.width * 100 + '%';
          this.userImgY = parseInt(userImgInfo.y) * zoomRate + 'px';
          // this.useImageYPercentage = useImageY / this.coverImgData.height * 100 + '%';
          // 计算用户图片大小()
          this.userImgW = parseInt(userImgInfo.w) * zoomRate + 'px';
          this.spanWidth = parseInt(userImgInfo.w);
          this.userImgH = parseInt(userImgInfo.h) * zoomRate + 'px';
          this.spanHeight = parseInt(userImgInfo.h);
          this.userImgData.src = userImgInfo.photoInfo.url;
          this.uploadPictureShow = false;
          this.userImgShow = true; // 用户图片层显示
          this.imgUploadingShow = false; // loading消失
          this.operateBorderShow = true; // 操作图层边框显示
          // this.operateBorder = '1px dashed #ff0000'
        }
      });
    },
    // 初始化画布参数
    initCanvasConfig() {
      this.canvasConfig.bgImg = this.productConfig.bgImg;
      this.canvasConfig.remindImg = this.productConfig.remindImg;
      this.canvasConfig.coverImg = this.productConfig.coverImg;
      this.canvasConfig.width = this.productConfig.width;
      this.canvasConfig.height = this.productConfig.height;
    },
    // 初始化定制区域参数
    initCusAreaConfig() {
      this.cusAreaConfig.w = this.productConfig.cusArea.w;
      this.cusAreaConfig.h = this.productConfig.cusArea.h;
      this.cusAreaConfig.x = this.productConfig.cusArea.x;
      this.cusAreaConfig.y = this.productConfig.cusArea.y;
    },
    // 计算定制区域的位置
    calculateCusPosition() {
      this.cusAreaH = this.cusAreaConfig.h / this.canvasConfig.height * 100 + '%';
      this.cusAreaW = this.cusAreaConfig.w / this.canvasConfig.width * 100 + '%';
      this.cusAreaX = this.cusAreaConfig.x / this.canvasConfig.width * 100 + '%';
      this.cusAreaY = this.cusAreaConfig.y / this.canvasConfig.height * 100 + '%';
      this.cusAreaShow = true;
      this.uploadPictureShow = true;
    },
    // 选取图片之后，上传图片，并获取服务器返回的url和图片宽高，计算图片的摆放位置和大小
    afterRead(file) {
      this.userImgX = '0px';
      this.userImgY = '0px';
      const canvasClient = document.getElementById('custom-canvas-image-box').getBoundingClientRect();
      this.clientScale = canvasClient.width / this.canvasConfig.width;
      this.imgUploadingShow = true;
      const imgFile = new FormData();
      imgFile.append('fileType', 'IMAGE');
      imgFile.append('file', file.file);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      post('/common/img/upload', imgFile, config).then(res => {
        this.addAddressFlag = false;
        this.userImgData.src = res.data.data.src;
        this.userImgData.width = res.data.data.width;
        this.userImgData.height = res.data.data.height;
        this.initImgPosition(this.userImgData.width, this.userImgData.height, this.clientScale);
        this.addAddressFlag = false;
        this.uploadPictureShow = false;
      });
    },
    onImageLoad() {
      this.canMove();
      if (!this.showGuideStorage) {
        this.showGuide = true;
      }
    },
    // 初始化图片位置，按照短边等比缩放到定制区域内部
    initImgPosition(userImageWidth, userImageHeight, scaleRate) {
      userImageWidth = userImageWidth * scaleRate;
      userImageHeight = userImageHeight * scaleRate;
      // 定制区域
      const parent = document.getElementById('custom-cusArea-box').getBoundingClientRect();
      const parentRate = parent.width / parent.height;
      const userImgRate = userImageWidth / userImageHeight;
      let userImgHNum = 0;
      let userImgWNum = 0;
      if (parentRate > userImgRate) {
        // 用户图片
        // if (userImageWidth < parent.width) {
        this.userImgW = parent.width + 'px';
        userImgWNum = parent.width;
        userImgHNum = parent.width / userImageWidth * userImageHeight;
        this.userImgH = userImgHNum + 'px';
      } else {
        this.userImgH = parent.height + 'px';
        userImgHNum = parent.height;
        userImgWNum = parent.height / userImageHeight * userImageWidth;
        this.userImgW = userImgWNum + 'px';
      }

      // 居中
      this.userImgX = (parent.width - userImgWNum) / 2 + 'px';
      this.userImgY = (parent.height - userImgHNum) / 2 + 'px';
      console.log(this.userImgX, this.userImgY);
      this.userImgShow = true; // 用户图片层显示
      this.imgUploadingShow = false; // loading消失
      this.operateBorderShow = true; // 操作图层边框显示

      return true;
    },
    // 每次移动完成完成之后，都需要计算准备提交的数据信息
    calImgPosition() {
      const userImgRect = document.getElementById('custom-user-image-box').getBoundingClientRect();
      // 定制区域的位置
      const cusAreaRect = document.getElementById('custom-cusArea-box').getBoundingClientRect();
      const rate = this.cusAreaConfig.w / cusAreaRect.width;
      this.userImgData.position.x = (userImgRect.x - 0 - cusAreaRect.x) * rate;
      this.userImgData.position.y = (userImgRect.y - 0 - cusAreaRect.y) * rate;
      this.userImgData.position.w = userImgRect.width * rate;
      this.userImgData.position.h = userImgRect.height * rate;
    },
    // 去结算页面
    goToBill() {
      const params = new URLSearchParams();
      params.append('cartItemIds', this.cartItemId);
      params.append('userAddressId', this.userDefaultAddressId);
      params.append('skuId', this.skuId);
      params.append('backUrl', `/product/custom?productId=${this.productId}&skuId=${this.skuId}`);
      const targetUrl = `http://www.ohhmycard.com/view/checkout?${params.toString()}`;
      window.location.href = targetUrl;
      return;
      this.$router.push({
        path: '/shoppingcart/bill',
        query: {
          cartItemIds: [this.cartItemId],
          userAddressId: this.userDefaultAddressId,
          skuId: this.skuId,
          productId: this.productId,
          backUrl: '/editor/custom?product_id=' + this.productId + '&sku_id=' + this.skuId
        }
      });
    },
    // 提交信息到购物车，并获取返回的购物车id
    postToShoppingCart() {
      this.calImgPosition();
      // eslint-disable-next-line eqeqeq
      // if (this.userDefaultAddressId != 0) {
      this.postOrderLoadingShow = true;
      const submitData = {
        productId: this.productId,
        skuId: this.skuId,
        count: 1,
        detail: {
          customize: [{
            userImgInfo: [{
              x: this.userImgData.position.x,
              y: this.userImgData.position.y,
              w: this.userImgData.position.w,
              h: this.userImgData.position.h,
              photoInfo: {
                url: this.userImgData.src,
                width: this.userImgData.width,
                height: this.userImgData.height
              }
            }],
            bgImages: [{
              url: this.canvasConfig.bgImg,
              width: this.cusAreaConfig.w,
              height: this.cusAreaConfig.h
            }],
            coverImgs: [this.productConfig]
          }]
        }
      };
      if (this.productConfig.hasOwnProperty('mask')) {
        submitData.detail.customize[0].mask = this.productConfig.mask;
      }
      if (this.productConfig.hasOwnProperty('cutOut')) {
        submitData.detail.customize[0].cutOut = this.productConfig.cutOut;
      }
      localStorage.setItem('localPictureInfoStorage' + this.skuId, JSON.stringify(submitData));
      submitCart(submitData).then(res => {
        // @todo 如果用户提交之后刷新页面，数据会丢失
        this.cartItemId = res.data.data.id;
        // 用户有默认地址，可以直接去结算页面
        this.goToBill();
      });
      // } else {
      //   this.popupAddAddress()
      // }
    },
    // 添加地址弹窗
    popupAddAddress() {
      this.addAddressFlag = true;
    },
    callbackForAdd(callbackData) {
      this.addAddressFlag = false;
      this.userDefaultAddressId = callbackData.addressId;
      this.postToShoppingCart();
    }
  }
};